import React from 'react';
import { Card, CardContent, Typography, Grid2, Box, Button } from '@mui/material';
import { People, Campaign, Receipt, Layers } from '@mui/icons-material'; // Sample icons


const data = [
  { title: 'Campaigns', value: '3', icon: <People />, description: 'LIVE' },
  { title: 'Campaigns', value: '5', icon: <People />, description: 'DRAFT' },
  { title: 'Campaigns', value: '2', icon: <People />, description: 'COMPLETED' },
  { title: 'Campaigns', value: '182', icon: <People />, description: 'ARCHIVED' },
];

const CampaignsOverview = ({campaignData}) => {
console.log(campaignData);



    return (
   
          <Grid2 container spacing={3} sx={{ width: '100%'}}>
               
           
          {data.map((item, index) => (
          <Grid2 item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  width: 300,    // Fixed width
                  height: 140,   // Fixed height
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 2,
                  backgroundColor: index === 0 ? '#00bf94' : '#fff',
                  color: index === 0 ? '#fff' : '#000',
                  borderRadius: 4,
                }}
              >
                {/* Left Side - Icon */}
                <Box>

                <div>
                    <Typography variant="h6" component="div">
                      {item.value}
                    </Typography>
                    <Typography variant="subtitle1">{item.title}</Typography>
                  </div> 
 
                 
                  <Typography variant="body2">{item.description}</Typography>
             
                  </Box>
               
    
                {/* Right Side - Stats */}
                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ bgcolor: '#f3f4f6', borderRadius: '50%', p: 2, mr: 2 }}>
                    {item.icon}
                  </Box>               </Box>
              </Card>
            </Grid2>
          ))}
        </Grid2> 
      );
    };

export default CampaignsOverview;
