import React, {useContext, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, Badge } from '@mui/material';
import { Home, CalendarToday, AccountBalance, BusinessCenter, InsertChart, ShoppingCart, Settings, Assignment, HeadsetMic, Layers } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = 280;

// Custom styles for the "NEW" badge
const NewBadge = styled('div')({
  fontSize: '10px',
  color: '#fff',
  backgroundColor: '#ff4757',
  padding: '2px 8px',
  borderRadius: '10px',
  marginLeft: 'auto',
});

const Sidebar = () => {
  
  const location = useLocation(); // Get the current location
  const user_type = localStorage.getItem('user_type');
  const navigate = useNavigate();

  
  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if(!user_type) {
      navigate('/logout');
    }
  }, []);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          padding: '24px',
          backgroundColor: '#fff', // White background
        },
      }}
    >
      {/* Sidebar Header */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          CrowdSwell
        </Typography>
      </Box>

      {/* List of Navigation Items */}
      <List>
        <Typography variant="overline" display="block" sx={{ color: 'gray' }}>
  
        </Typography>

        {[
          { text: 'Home', icon: <Home />, path: '/' },
          { text: 'Creators Dashboard', icon: <Home />, path: '/campaigns/creators'},
          { text: 'Campaigns', icon: <AccountBalance />, path: '/campaigns'},
          { text: 'Social Media', icon: <ConnectWithoutContactIcon />,path: '/social-media' },
          
      {/*}    
          { text: 'Tickets', icon: <BusinessCenter />,path: '/tickets' },
          { text: 'Support', icon: <InsertChart />,path: '/support'}, // CRM with a "NEW" badge
          { text: 'Job Board', icon: <Layers />,path: '/job-board' }, // Active item highlighted
              { text: 'Projects', icon: <CalendarToday />,path: '/projects' },

          */}
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            sx={{
              padding: '12px 16px',
              borderRadius: '10px',
              backgroundColor: location.pathname === item.path ? '#f3f4f6' : 'inherit', // Highlight the active item
              color: item.active ? '#1976d2' : '#000',
              '&:hover': {
                backgroundColor: '#f3f4f6',
              },
            }}
          >
            <ListItemIcon sx={{ color: item.active ? '#1976d2' : '#000' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
            {item.new && <NewBadge>NEW</NewBadge>}
          </ListItem>
        ))}

        <Typography variant="overline" display="block" sx={{ color: 'gray', mt: '20px'}}>
          Admin
        </Typography>

        {[
          { text: 'Log Out', icon: <LogoutIcon />, path: '/logout' },
     
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            sx={{
              padding: '12px 16px',
              borderRadius: '10px',
              backgroundColor: location.pathname === item.path ? '#f3f4f6' : 'inherit', // Highlight the active item
              color: item.active ? '#1976d2' : '#000',
              '&:hover': {
                backgroundColor: '#f3f4f6',
              },
            }}
          >
            <ListItemIcon sx={{ color: item.active ? '#1976d2' : '#000' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
            {item.new && <NewBadge>NEW</NewBadge>}
          </ListItem>
        ))}
    
      </List>
    </Drawer>
  );
};

export default Sidebar;
