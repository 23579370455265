export const statusColors = {
    Live: { backgroundColor: 'green', color: 'white' },
    Paused: { backgroundColor: 'orange', color: 'white' },
    Cancelled: { backgroundColor: 'red', color: 'white' },
    Complete: { backgroundColor: 'blue', color: 'white' },
    Draft: { backgroundColor: 'grey', color: 'white' },
    default: { backgroundColor: 'grey', color: 'white' },
    live: { backgroundColor: 'green', color: 'white' },
    paused: { backgroundColor: 'orange', color: 'white' },
    cancelled: { backgroundColor: 'red', color: 'white' },
    complete: { backgroundColor: 'blue', color: 'white' },
    draft: { backgroundColor: 'grey', color: 'white' },
    default: { backgroundColor: 'grey', color: 'white' }
  };