import React, { useState } from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar, CircularProgress  } from '@mui/material';
import Sidebar from '../components/navigation/MainNav';
import SearchBar from '../components/widgets/dashboard/SearchBar';
import DashboardOverview from '../components/widgets/dashboard/DashboardOverview';
import CampaignSummaryTable from '../components/widgets/dashboard/CampaignSummaryTable';
import RecentActivities from '../components/widgets/dashboard/RecentActivity';
import RequiredTasks from '../components/widgets/dashboard/RequiredTasks';
import ParticipantsLeaderboard from '../components/widgets/dashboard/ParticipantsLeaderboard';
import SettingsIcon from '@mui/icons-material/Settings';
import CreateCampaign from '../components/widgets/buttons/createCampaign';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';



const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout')
    }
    getCampaigns();
  }, []);


  const getCampaigns = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/list',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setCampaignData(response.data.jobs || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress /> {/* Circular loader centered */}
        </Box>
      ) : (
        <>
  
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
       <Box>
       <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2}}>
        <Grid2 item xs>
          <SearchBar />
          </Grid2><Grid2 item>
        <Grid2 container alignItems="center" spacing={2}>
          <Grid2 item>
            <SettingsIcon />
          </Grid2>
          <Grid2 item>
            <Avatar>JG</Avatar>
          </Grid2>
        </Grid2>
      </Grid2> 
        </Grid2>
        <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4}}>
       
       <Typography variant="h6" sx={{fontWeight: 'bold'}}>Overview</Typography>
       <CreateCampaign/>

       
        
        </Grid2>
        <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '100%'}}>
           <DashboardOverview />
           </Grid2>
           <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%'}}>
           <CampaignSummaryTable campaignData={campaignData} />
           </Grid2>
           <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%'}}>
           <RecentActivities />
           <RequiredTasks />
           </Grid2>
           <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%'}}>
           <ParticipantsLeaderboard />
           </Grid2>
     
           </Box>
        </Container>
      </Box>
      </>
      )}
    </Box>
  );
};

export default Dashboard;
