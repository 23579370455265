import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Avatar,
  Chip,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';


const CampaignSummaryTable = ({campaignData}) => {
  const navigate = useNavigate();

  const handleOnClick = async (row) => {
        navigate(`/campaigns/detail/${row._id}`);
    }

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
      <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold'  }}>
        Campaign Summary
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>ADVERTISER</strong></TableCell>
            <TableCell><strong>CAMPAIGN NAME</strong></TableCell>
            <TableCell><strong>START DATE</strong></TableCell>
            <TableCell><strong>END DATE</strong></TableCell>
            <TableCell><strong>ASSIGNED TO</strong></TableCell>
            <TableCell><strong>STATUS</strong></TableCell>
            <TableCell><strong>PROGRESS</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaignData.map((row, index) => (
            <TableRow key={index} onClick={() => handleOnClick(row)}>
              <TableCell>{row.advertiserName}</TableCell>
              <TableCell>{row.campaignName}</TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>{row.endDate}</TableCell>
              <TableCell>{row.assignedTo}</TableCell>
              <TableCell>
               <Chip label={row.status} 
               sx={{ backgroundColor: statusColors[row.status]?.backgroundColor || statusColors.default.backgroundColor,
                    color: statusColors[row.status]?.color || statusColors.default.color
                }}/>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex'}}>
                  <CircularProgress
                    variant="determinate"
                    value={row.progress}
                    size={30}
                    sx={{ color: row.statusColor, marginRight: 1 }}
                  />
                  <Typography>{`${row.progress}%`}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CampaignSummaryTable;
