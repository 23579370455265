import React, { useState } from 'react';
import { Box, Grid2, Button, Card, CardContent, Chip, Typography } from '@mui/material';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';

const CardComponent = ({ card }) => {
  const navigate = useNavigate();
  // Handle missing fields by providing default values
  const {
    jobType = '',   
    image1 = '',           // Default value for job type
    advertiserName = 'Unknown Advertiser',   // Default value for advertiser
    campaignName = 'Unnamed Campaign',       // Default value for campaign name
    campaignSummary = 'No summary available',// Default value for campaign summary
    endDate = 'No end date',                 // Default value for end date
    tagColor = 'grey',                        // Default color for the tag
    statusColor = 'grey',
    status = 'draft'                      // Default color for the status
  } = card;


const handleOnClick = async (e) => {
  e.preventDefault();
      navigate(`/campaigns/detail/${card._id}`);
  }


  return (
    <Card sx={{ maxWidth: 350, position: 'relative' }} onClick={handleOnClick}>
      <CardContent>
      <Box maxWidth="350px" sx={{ position: 'relative' }}>
        <img src={image1} alt='missing' style={{ width: '100%', height: 'auto' }}/>
      </Box>
        {/* Tag */}
        <Chip
          label={status}
          sx={{ 
            position: 'absolute',
            top: 24,   // 2px padding equivalent (can adjust)
            right: 24, // 2px padding equivalent (can adjust)
            backgroundColor: statusColors[status]?.backgroundColor || statusColors.default.backgroundColor,
            color: statusColors[status]?.color || statusColors.default.color
           }}
        />
        <Typography variant="body2" color="textSecondary">
          {advertiserName}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {campaignName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {campaignSummary}
        </Typography>
        {/* Status */}
        <Typography variant="h6" sx={{ color: statusColor, mt: 1 }}>
          Ending {endDate}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CardGrid = ({campaignData}) => {
  const [visibleCards, setVisibleCards] = useState(6);
  //const [campaignCardData, setCampaignCardData] = useState([]);

  // Handle "View More" button click
  const handleViewMore = () => {
    setVisibleCards((prev) => prev + 9); // Load 9 more cards on each click
  };

  // Create an array of visible cards
  const displayedCards = [];
  for (let i = 0; i < visibleCards; i++) {
    if (campaignData.length > 0) {
      displayedCards.push(campaignData[i % campaignData.length]); // Loop through the available cards
    }
  }

  {/*}
  // Fetch campaigns from the API
  useEffect(() => {
    getCampaigns();
  }, []);

  const getCampaigns = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/list',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setCampaignCardData(response.data.jobs || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  */}

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Grid2 container spacing={3}>
        {displayedCards.map((campaign, index) => (
          <Grid2 item xs={12} sm={6} md={4} key={index}>
            {/* Pass the card data to the CardComponent */}
            <CardComponent card={campaign} />
          </Grid2>
        ))}
      </Grid2>

      {/* "View More" Button */}
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button variant="contained" onClick={handleViewMore}>
          View More
        </Button>
      </Box>
    </Box>
  );
};

export default CardGrid;
