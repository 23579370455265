import React, { useState } from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar, CircularProgress } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import CreatorsCampaignsOverview from '../../components/widgets/campaigns/CreatorsCampaignsOverview';
import SettingsIcon from '@mui/icons-material/Settings';
import CardGrid from '../../components/widgets/campaigns/campaignsCards';
import CreateCampaign from '../../components/widgets/buttons/createCampaign';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const CreatorsCampaignsPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const user_type = localStorage.getItem('user_type');
  const [campaignData, setCampaignData] = useState([]);

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout')
    }
    getCampaigns();
  }, []);


  const getCampaigns = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/list',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setCampaignData(response.data.jobs || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
          <CircularProgress /> {/* Circular loader centered */}
        </Box>
      ) : (
        <>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
       <Box>
       <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2}}>
        <Grid2 item xs>
          <SearchBar />
          </Grid2><Grid2 item>
        <Grid2 container alignItems="center" spacing={2}>
          <Grid2 item>
            <SettingsIcon />
          </Grid2>
          <Grid2 item>
            <Avatar>JG</Avatar>
          </Grid2>
        </Grid2>
      </Grid2> 
        </Grid2>
        <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4}}>
       
       <Typography variant="h6" sx={{fontWeight: 'bold'}}>Overview</Typography>
        <CreateCampaign />
       
        
        </Grid2>
        <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '100%'}}>
           <CreatorsCampaignsOverview campaignData={campaignData}/>
           </Grid2>
           <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%'}}>
           <CardGrid campaignData={campaignData}/>
           </Grid2>
          </Box>
        </Container>
      </Box>
      </>
      )}
    </Box>
  );
};

export default CreatorsCampaignsPage;
